<template>
  <div class="zjzx">
    <div class="header">
      <div class="h1">专家咨询</div>
      <div class="h2">专家把脉  共创辉煌</div>
    </div>
    <div class="bg">
      <div class="zhuti">
        <!-- <div class="flex title">
          <div class="flex-1 left flex">
            <div class="zhuanjia"></div>
            <div class="flex-1">
              <div class="h1">我是专家</div>
              <div class="h2">我想加入专家库</div>
              <div class="button m2" @click="goto(1)">
                专家认证<i class="el-icon-arrow-right"></i>
              </div>
            </div>

          </div>
          <div class="flex-1 right flex">
            <div class="qiye"></div>
            <div class="flex-1">
              <div class="h1">我是企业</div>
              <div class="h2">我想咨询专家</div>
              <div class="button m1" @click="goto(2)">
                专家列表<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div> -->
        <div class="lists">
          <div class="titles">企业难题 一站解决</div>
          <div class="s2">多行业领域权威专家坐镇，为您的企业提供专业建议和解决方案，优化企业流程，协助企业决策</div>
<!--          <div class="lis" v-for="(li,index) in lis">-->
<!--            <div class="imgs"></div>-->
<!--            <div>{{li.text}}</div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="bg3">
        <div class="zhuti rmzj">
          <div class="rmtitle">热门专家推荐 <span class="more" @click="goto(2)">查看更多<i class="el-icon-arrow-right"></i></span></div>
        </div>
      </div>
      <div class="bg1" style="padding: 55px 0">
        <div class="zhuti">
          <div class="titles">已成功咨询名单</div>
          <div >
            <el-table
                :data="arrs"
                style="width: 100%">
              <el-table-column
                  prop="name"
                  width="160"
                  label="专家姓名">
              </el-table-column>
              <el-table-column
                  prop="lingyu"
                  width="160"
                  label="擅长领域">
              </el-table-column>
              <el-table-column
                  prop="jieshao"
                  label="专家介绍">
              </el-table-column>
              <el-table-column
                  prop="phone"
                  width="160"
                  label="联系方式">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  width="160"
                  label="发布时间">
              </el-table-column>
            </el-table>
          </div>

        </div>
      </div>
      <div class="bg" style="padding: 40px">
        <div class="zhuti">
          <div class="titles">咨询案例</div>
          <div class="bottoms">
            <div v-for="(an,index) in anli" style="margin-bottom: 45px" :key="index">
              <div class="wentitle"><span class="wen">问</span>{{an.title}}</div>
              <div class="flex people">
                <div class="touxiang"><el-avatar :size="68" src=""></el-avatar></div>
                <div class="flex-1">
                  <div class="pren">{{an.name}}     <span class="zhiwei">主任律师</span></div>
                  <div class="address"><el-tag size="small">河北中瑞税务师事务所</el-tag></div>
                </div>
              </div>
              <div class="info">
                1、收集证据：首先，收集所有与该专利有关的证据，包括专利证书、专利申请文件、侵权证据等。这些证据将有助于证明您的专利权并
                支持您的案件。

                2、联系当地专利局：联系当地专利局，了解有关该专利的法律程序和规定。他们可以提供有关专利权保护的信息和建议。

                3、寻求法律帮助：如果您的公司拥有律师，可以咨询他们，了解有关该专利的法律程序和可能的维权方法。如果您的...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import config from "../../utils/config";

export default {
  name: "zjzx",
  components:{
  },
  data(){
    return  {
      anli:[{title:'我公司的新产品被其他公司侵犯了专利权，我们公司该如何维权？',name:'李毅'},{title:'我公司的新产品被其他公司侵犯了专利权，我们公司该如何维权？',name:'李毅'}],
      arrs:[{name:'于xx ',lingyu:'生产制造',jieshao:'高级咨询顾问、品牌讲师、云产品专家顾问。\n' +
            '熟悉智能制造、财税一体化、业务协同办公一体化。\n' +
            '20多年企业信息化建设经验，从事制造企业信息化咨询、服务工作，积累了丰富的实践与交流经验，受到客户信任和好评。',phone:'152******32',createTime:'2024-02-03'},
        {name:'于xx ',lingyu:'生产制造',jieshao:'高级咨询顾问、品牌讲师、云产品专家顾问。\n' +
              '熟悉智能制造、财税一体化、业务协同办公一体化。\n' +
              '20多年企业信息化建设经验，从事制造企业信息化咨询、服务工作，积累了丰富的实践与交流经验，受到客户信任和好评。',phone:'152******32',createTime:'2024-02-03'},
        {name:'于xx ',lingyu:'生产制造',jieshao:'高级咨询顾问、品牌讲师、云产品专家顾问。\n' +
              '熟悉智能制造、财税一体化、业务协同办公一体化。\n' +
              '20多年企业信息化建设经验，从事制造企业信息化咨询、服务工作，积累了丰富的实践与交流经验，受到客户信任和好评。',phone:'152******32',createTime:'2024-02-03'},
        {name:'于xx ',lingyu:'生产制造',jieshao:'高级咨询顾问、品牌讲师、云产品专家顾问。\n' +
              '熟悉智能制造、财税一体化、业务协同办公一体化。\n' +
              '20多年企业信息化建设经验，从事制造企业信息化咨询、服务工作，积累了丰富的实践与交流经验，受到客户信任和好评。',phone:'152******32',createTime:'2024-02-03'}],
      lis:[{text:'法务'},{text:'财税金融'},{text:'企业培训'}],
      items:[{text:'法务',type:[{text:'劳务法咨询'},{text:'税法咨询'},{text:'合同咨询'}]},{text:'财税金融',type:[{text:'劳务法咨询'},{text:'税法咨询'},{text:'合同咨询'}]},
        {text:'企业培训',type:[{text:'劳务法咨询'},{text:'税法咨询'},{text:'合同咨询'}]}]
    }
  },
  methods:{
    goto(num){
      if(num==1){
        window.open(config.adminhref+'#/expert/index','_blank')
      }else{
        this.$router.push({
          path:'zjzxlist'
        })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table th.is-leaf{
  background-color: #fff!important;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  margin: 0 56px 56px 0;
  color: #1D1D1D;
}
.zhuanjia{
  width: 49px;
  height: 45px;
  background: url("../../assets/zjzx/zhuanjia.png");
  background-size: 100% 100%;
  margin: 0 15px;
}
.rmzj{
  padding: 56px 0;
  .rmtitle{
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
  }
  .more{
    float: right;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
  }
}
.qiye{
  width: 49px;
  height: 45px;
  background: url("../../assets/zjzx/qiye.png");
  background-size: 100% 100%;
  margin: 0 15px;
}
.bg{
  background: #fff;
}
.bg1{
  background: #F0F5FE;
}
.bg3{
  background: #353D58;
}
.wen{
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: rgba(0, 121, 233, 0.10);
  color: #0079E9;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  line-height: 32px;
  margin-right: 20px;
}
.wentitle{
  font-size: 16px;
}
  .zjzx{
    .header{
      height: 180px;
      background: url("../../assets/banner/zjzx.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .zhuti{
      width: 1200px;
      margin: 0px auto;
      .bottoms{
        .people{
          margin: 20px 0;
          .pren{
            font-size: 18px;
            font-weight: bolder;
          }
          .zhiwei{
            font-size: 14px;
            color: #999;
            margin-left: 15px;
          }
        }
        .address{
          padding-top: 10px;
        }
        .touxiang{
          margin-right: 35px;
        }
        .info{
          padding: 30px;
          border-radius: 4px;
          background: #F9FBFF;
          margin-left: 95px;
          line-height: 30px;
          color: #666;
        }
      }
      .ren{
        text-align: center;
        line-height: 35px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 15px 0;
        color: #999;
        .head{
          color: #1D1D1D;
          border-bottom: 1px solid #eeeeee;
        }
      }
      .items{
        margin: 0 20px;
        .biaoti{
          font-weight: 550;
          font-size: 16px;
          margin-bottom: 25px;
          span{
            border-bottom: 2px solid #E7BE8A;
            padding: 2px 10px;
          }
        }
        .btn{
          float: left;
          padding: 10px 28px;
          margin:10px 30px;
          border-radius: 55px;
          color: #fff;
        }
        .more{
          width: 350px;
          text-align: center;
          background: #ECECEC;
          padding: 10px 0;
          margin: 15px 0;
        }
        .img{
          background: #0078F2;
          width: 350px;
          height: 200px;
        }
      }
      .lists{
        padding: 36px;
        overflow: hidden;
        text-align: center;
        .lis{
          float: left;
          margin: 0 36px;
        }
        .imgs{
          width: 100px;
          height: 100px;
          margin: 15px 0;
        }
      }
      .h1{
        font-size: 18px;
        font-weight: 550;
      }
      .h2{
        line-height: 30px;
      }
      .button{
        position: absolute;
        top: 28px;
        right: 20px;
        border-radius: 4px;
        padding: 8px 20px;
        color: #fff!important;
      }
      .m1{
        background: #FF820F;
        cursor: pointer;
      }
      .m2{
        background: #1C92FF;
        cursor: pointer;
      }
      .left{
        background: linear-gradient(180deg, #E7F1FF 0%, #FFF 100%);
        padding: 20px;
        position: relative;
        color: #1D1D1D;
        border: 1px solid #FFF;
        margin: 37px 20px 37px 0;
      }
      .right{
        background: linear-gradient(180deg, #FFF3E7 0%, #FFF 100%);
        padding: 20px;
        position: relative;
        margin: 37px 0;
        color: #1D1D1D;
        border: 1px solid #FFF;
      }
    }
  }
</style>